import { mapGetters } from 'vuex'
import { PLATFORM_TYPE } from '@/constants/platform'

export const PluginUtils = {
  computed: {
    ...mapGetters({
      browzwearAPI: 'getBrowzwearAPI'
    }),

    /**
     * @returns {string}
     */
    platform () {
      if (this.browzwearAPI) {
        return PLATFORM_TYPE.BROWZWEAR
      }

      return PLATFORM_TYPE.WEB
    },

    /**
     * @returns {boolean}
     */
    isBrowzwear () {
      return Boolean(this.browzwearAPI)
    }
  }
}
