var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"the-aside-left"},[_c('nav',{staticClass:"the-aside-left__nav"},_vm._l((_vm.visibleMenuItems),function(value,name){return _c('div',{key:name,class:[
        'the-aside-left__nav-menu',
        { 'the-aside-left__nav-menu--bottom': _vm.isSettingsMenu(name) }
      ]},[(!_vm.isSettingsMenu(name))?_c('h2',{staticClass:"the-aside-left__nav-menu-title"},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e(),_vm._l((value),function(library,index){return [_c('router-link',{key:(name + "-library-" + index),class:[
            'the-aside-left__nav-menu-item',
            {
              'the-aside-left__nav-menu-item--settings': _vm.isSettingsMenu(name)
            }
          ],attrs:{"to":{ path: library.link }}},[_c('i',{staticClass:"the-aside-left__nav-menu-item-icon",style:({
              webkitMask: ("url('" + (library.icon) + "') no-repeat center"),
              mask: ("url('" + (library.icon) + "') no-repeat center"),
              maskSize: 'contain',
              webkitMaskSize: 'contain'
            })}),_vm._v(" "+_vm._s(library.title)+" "),_c('i',{staticClass:"el-icon-arrow-right the-aside-left__nav-menu-item-arrow-icon"})])]})],2)}),0),_c('div',{staticClass:"the-aside-left-version"},[_vm._v(" Build ID: "+_vm._s(_vm.version)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }